.player-info-level-display-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.player-info-level-display-background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 110px;
    height: 38px;
    user-select: none;
}

.player-info-level-display-icon {
    position: absolute;
    transform: translate(-50%, -50%);
    left: -24%;
    top: 50%;
    width: 34px;
    height: 35px;
    user-select: none;
}

.player-info-level-display-title-text {
    position: absolute;
    font-size: 8px;
    margin: 0;
    color: white;
    text-align: left;
    top: 20%;
    left: -3%;
    width: 40px;
    height: 8px;
    font-family: "mishmash";
    white-space: break-spaces;
    pointer-events: none;
    user-select: none;
}

.player-info-level-display-amount-text {
    position: absolute;
    font-size: 8px;
    margin: 0;
    color: white;
    text-align: center;
    top: 59%;
    left: -3%;
    width: 25px;
    height: 8px;
    font-family: "mishmash";
    white-space: break-spaces;
    pointer-events: none;
    user-select: none;
}

.player-info-level-display-interest-rate-text {
    position: absolute;
    font-size: 7px;
line-height: 10px;
    margin: 0;
    color: #5CFFFF;
    top: 55%;
    left: 85%;
    width: 50px;
    height: 30px;
    font-family: "mishmash";
    white-space: break-spaces;
    pointer-events: none;
    user-select: none;
    transform: translate(-50%, -50%);
}

.player-info-level-display-hover-range {
    position: relative;
    width: 100%;
    height: 100%;
}

.player-info-level-display-hover-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: -10%;
    user-select: none;
}